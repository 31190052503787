import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Pre Open WOD 2`}</strong></p>
    <p>{`From 0:00-5:00:`}</p>
    <p>{`Establish a 5RM Bench Press`}</p>
    <p><em parentName="p">{`(butt stays on bench, bar must touch chest all 5 reps and come to full
extension)`}</em></p>
    <p>{`From 5:00-13:00:`}</p>
    <p>{`8:00 AMRAP of`}</p>
    <p>{`8-Ring Dips`}</p>
    <p><em parentName="p">{`(scaled men use red band, women use blue band.  masters men use blue
band, women use medium band)`}</em></p>
    <p>{`8-CTB Pullups`}</p>
    <p><em parentName="p">{`(scaled & masters=ring rows)`}</em></p>
    <p>{`50ft shuttle run in 25ft increments`}</p>
    <p><em parentName="p">{`(25ft=1 point)`}</em></p>
    <p>{`From 13:00-15:00:`}</p>
    <p>{`Max Ring Muscle Ups`}</p>
    <p><em parentName="p">{`(scaled & masters=unassisted pullups)`}</em></p>
    <p>{`*`}{`*`}{`Each pound on the bench press (weight of 5rm), rep, and 25ft shuttle
run increment = 1 point.  Score = total points.`}{`*`}{`*`}</p>
    <p><strong parentName="p">{`*`}{`Saturday, January 13th is Cardinal Fitness Day at the Yum!.  The
Cards play Virginia Tech at 4:00pm.  With the following link you can get
discounted tickets as a CrossFit the Ville member!  After the game there
will be a CrossFit wod on the court along with several other workout
sessions.  Lets get a big group together and represent The Ville at the
Yum center!  Family is invited as well.`}</strong></p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://fevo.me/fitday"
        }}>{`https://fevo.me/fitday`}</a></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start January 7th.  Email Eric for
more info: Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      